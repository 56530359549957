
/* Definição de um filtro de luz (efeito de luz mais lento) */
@keyframes breakEffect {
  0% { filter: contrast(100%) brightness(100%); }
  50% { filter: contrast(150%) brightness(120%); }
  100% { filter: contrast(100%) brightness(100%); }
}

/* Classe para aplicar apenas o efeito de luz */
.shake-effect {
  animation: breakEffect 5s infinite; /* Efeito de luz mais lento (5 segundos) */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.inputlabel{ opacity:0}

.shadowinput{
  box-shadow: 0 0 3px 1px #EFAB00!important
}

/* Primeira Seção: div ocupando 100% de largura */
.checks-wrapper {
  background-color: #cccccc; /* Cor cinzenta */
  width: 100%;
  box-sizing: border-box; /* Certifique-se de que o padding não afete a largura total */
}

/* Padding responsivo para a primeira seção */
@media (max-width: 768px) {
  .checks-wrapper {
    padding: 50px; /* Padding de 10px no mobile */
  }
}

@media (min-width: 768px) {
  .checks-wrapper {
    padding: 40px; /* Padding de 40px no desktop */
  }
}

/* Primeira Seção */
.checks {
  padding: 0; /* Remove o padding interno da section */
}

.tw-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Layout para a primeira seção */
.tw-grid {
  display: flex;
  flex-direction: column; /* Coluna no mobile */
  gap: 20px;
}

.benefit {
  display: flex;
  align-items: flex-start; /* Alinhado ao topo */
  text-align: left;
  margin-bottom: 20px; /* Espaço entre cada benefício */
}

.icon {
  font-size: 30px; /* Tamanho do ícone */
  margin-right: 16px; /* Espaço à direita do ícone */
}

.benefit-text {
  margin: 0; /* Remover a margem padrão do parágrafo */
}

/* Responsividade para a primeira seção no desktop */
@media (min-width: 768px) {
  .tw-grid {
    flex-direction: row; /* Mudar para linha no desktop */
    justify-content: space-between; /* Espaçamento entre elementos */
  }

  .benefit {
    flex: 1 1 20%; /* Cada item ocupa um espaço proporcional */
  }
}

/* Segunda Seção */
.footer-info {
  padding: 40px 20px;
  margin-top: 30px; /* Margem superior de 30px */
  text-align: center; /* Alinhado ao centro */
}

.footer-container {
  max-width: 90%; /* Máximo de 90% da largura no desktop */
  margin: 0 auto;
  display: flex;
  align-items: flex-start; /* Alinhar logo e texto ao topo */
  justify-content: center; /* Alinhar os elementos horizontalmente */
  gap: 20px; /* Espaço entre logo e texto */
  flex-wrap: wrap; /* Para ajuste no mobile */
}

/* Logo e texto alinhados ao topo */
.footer-logo {
  flex: 0 1 150px; /* Logo ocupa um tamanho fixo */
  display: flex;
  align-items: flex-start; /* Alinhar ao topo */
}

.footer-text {
  flex: 1;
  text-align: left; /* Texto alinhado à esquerda */
  font-size: 14px;
}

/* Responsividade para a segunda seção no mobile */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Colocar elementos em coluna no mobile */
    align-items: center;    /* Centralizar no mobile */
  }

  .footer-text {
    text-align: center; /* Centralizar o texto no mobile */
    /* Espaçamento entre logo e texto */
  }
}
/* Primeira Seção: Div ocupando 100% de largura */
.benefit-item {
  display: grid;
  grid-template-columns: 40px 1fr; /* Duas colunas: ícone à esquerda e texto à direita */
  align-items: start; /* Alinhar os elementos ao topo */
  background: rgba(239, 171, 0, 0.8); /* Cor de fundo com 20% de transparência */
  color: white; /* Texto branco */
  padding: 10px; /* Espaçamento interno */
  border-radius: 8px; /* Cantos arredondados */
  transition: background 0.3s ease-in-out; /* Efeito de transição no hover */
  width: 100%; /* Ocupar 100% da largura */
  height: 80px;
}

.benefit-item:hover {
  background: rgba(239, 171, 0, 1); /* No hover, fundo com 100% de opacidade */
}

.benefit-icon {
  margin-top: 0; /* Alinhado ao topo */
}

.benefit-text {
  text-transform: uppercase; /* Texto em maiúsculas */
  font-weight: bold;
  font-size: 18px;
  margin: 0; /* Remover margens padrão */
}

/* Layout Responsivo */
@media (max-width: 768px) {
  .benefit-item {
    grid-template-columns: 30px 1fr; /* Ajustar tamanho do ícone no mobile */
    padding: 8px;
  }
}
.conversion-btnbasic {
  background-color: #28a745; /* Cor verde que indica sucesso/ação positiva */
  color: white; /* Cor do texto */
  padding: 15px 30px; /* Tamanho do botão */
  border: none; /* Sem borda */
  border-radius: 5px; /* Bordas arredondadas */
  font-size: 18px; /* Tamanho da fonte maior */
  font-weight: bold; /* Texto em negrito */
  cursor: pointer; /* Cursor em forma de mão para interação */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Suavidade na animação */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para dar profundidade */
 
}
.conversion-btn {
  background-color: #28a745; /* Cor verde que indica sucesso/ação positiva */
  color: white; /* Cor do texto */
  padding: 15px 30px; /* Tamanho do botão */
  border: none; /* Sem borda */
  border-radius: 5px; /* Bordas arredondadas */
  font-size: 18px; /* Tamanho da fonte maior */
  font-weight: bold; /* Texto em negrito */
  cursor: pointer; /* Cursor em forma de mão para interação */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Suavidade na animação */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para dar profundidade */
  animation: tremer 1.5s infinite; /* Controla a velocidade */
}
.price-highlight {
 
  color: #000000; /* Cor do texto */
  border-radius: 5%; /* Torna o elemento redondo */
  padding: 8px; /* Espaçamento interno */
 
  font-weight: bold; /* Texto em negrito */
  font-size: 44px; /* Tamanho do texto */
 
 
}

.conversion-btn:hover {
  background-color: #218838; /* Cor mais escura ao passar o mouse */
  transform: translateY(-2px); /* Leve movimento para cima no hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Sombra mais forte no hover */
}

.conversion-btn:active {
  background-color: #1e7e34; /* Cor ainda mais escura ao clicar */
  transform: translateY(0); /* Volta ao nível normal ao clicar */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Volta à sombra original */
}

@keyframes tremer {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Colocar elementos em coluna no mobile */
    align-items: center;    /* Centralizar no mobile */
  }

  .footer-logo img {
    width: 300px; /* Define um tamanho para a imagem no mobile */
    max-width: 100%; /* Garante que a imagem não ultrapasse o container */
  }

  .footer-text {
    text-align: center; /* Centralizar o texto no mobile */
 
  }
}
.slick-arrow {
  color: #000 !important;
  fill: #000 !important;
}


.pulse-effect {
  animation: pulseLight 10s ease-in-out infinite; /* Aumentando a duração para 5 segundos */
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes pulseLight {
  0%, 100% {
    opacity: 0.9; /* Mais escuro no início e no final */
  }
  50% {
    opacity: 0.8; /* Menos claro no meio do ciclo */
  }
}
.content {
  padding: 20px;
}

/* Estilo da notificação */
.slide-in-unique {
  position: fixed;
  bottom: 20px;
  right: -350px; /* Fora da tela inicialmente */
  width: 350px;
  font-size: 16px;
  padding: 20px;
  background-color: #F0F1F3;
  color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: right 0.5s ease, opacity 0.5s ease; /* Animação de deslizamento e opacidade */
  z-index: 1000;
  

}

.slide-in-unique.active-unique {
  right: 20px; /* Move a notificação para dentro da tela */
  opacity: 1;
}

.slide-in-unique.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0s 0.5s; /* Fica invisível após a animação de opacidade */
}

.close-btn-unique {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}

 /* Layout responsivo para o grid */
.benefits-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 0 auto;
  max-width: 90%; /* Largura máxima de 90% para desktops */
  text-align: left; /* Garante o alinhamento à esquerda para o grid */
}

.grid-item {
  flex: 1 1 100%; /* Um item por linha no mobile */
  box-sizing: border-box;
  margin-bottom: 16px;
 
  text-align: left; /* Garante que o texto fique alinhado à esquerda */

}

/* Para telas maiores (desktop), exibir dois itens por linha */
@media (min-width: 768px) {
  .grid-item {
    flex: 1 1 calc(50% - 16px); /* Dois itens por linha no desktop */
    text-align: left; /* Garante que o texto fique alinhado à esquerda */
  }
}

/* Estilo do Accordion */
.accordion-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.accordion-header {
  display: flex;
  justify-content: flex-start; /* Alinha horizontalmente à esquerda */
  align-items: flex-start; 
  position: relative; 
  text-align: left; /* Garante que o título do accordion fique alinhado à esquerda */
  padding: 20px;
  background: #fff;
  color: #F5C340;
  cursor: pointer;
}

.accordion-header:hover {
  background: #F5C340;
  color: #000;
}

.accordion-header h3.accordion-title {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase; /* Todo o título em maiúsculas */
  text-align: left; /* Garante que o título fique alinhado à esquerda */
  vertical-align: top; /* Alinha o texto no topo */
  display: inline-block; /* Garante que o vertical-align tenha efeito */
}

/* Estilo do ícone à esquerda do título */
.accordion-icon {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.accordion-toggle {
  position: absolute;
  top: 0; /* Alinha no topo */
  right: 0  ; /* Alinha à direita */
  font-size: 24px;
  padding: 20px;
  font-weight: bold;
  
}

.accordion-toggle.open {
  color: #F5C340;
}
.accordion-content {
  padding: 16px;
  background: #fafafa;
  border-top: 1px solid #ddd;
}

.accordion-content p {
  margin: 0;
  font-size: 18px;
  color: #555;
}

.accordion-header:hover,
.accordion-header.active {
  background: #F5C340; /* Cor de fundo ao passar o mouse ou abrir o accordion */
  color: #000; /* Cor do texto */
}

.accordion-header.active .accordion-toggle {
  color: #000; /* Altera a cor do botão ao abrir */
}


:root {
  --main-color: #2CC157;
  --accent-pink: #EDB9FF;
  --accent-blue: #36C5F0;
  --text-color: #010216;
  --font-size-lg: 1.2rem;
  --font-size-md: 1rem;
  --font-size-sm: 0.875rem;
  --spacing-md: 16px;
  --spacing-lg: 32px;
}

.c_inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-lg);
}

.content_wrapper, .list_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left_column, .right_column {
  flex: 1;
  padding: var(--spacing-md);
}

.dot {
  width: 12px;
  height: 12px;
  background-color: var(--main-color);
  border-radius: 50%;
  display: inline-block;
}

.label {
  font-size: var(--font-size-lg);
  color: var(--text-color);
}

.text {
  font-size: var(--font-size-md);
  color: var(--text-color);
}

.accent-dot {
  color: var(--main-color);
}

.accent-dot-pink {
  color: var(--accent-pink);
}

.accent-dot-blue {
  color: var(--accent-blue);
}

.arrow {
  border-left: 8px solid var(--main-color);
  margin-right: 8px;
}

.arrow-pink {
  border-left: 8px solid var(--accent-pink);
}

.arrow-blue {
  border-left: 8px solid var(--accent-blue);
}

/* Responsivo */
@media screen and (max-width: 768px) {
  .content_wrapper, .list_wrapper {
      flex-direction: column;
  }

  .left_column, .right_column {
      padding: var(--spacing-md) 0;
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1b1b1b;
  color: #fff;
}

.graphic-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  transition: opacity 1s ease-in-out;
}

.progress-circle {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #2c2c2c;
  border: 5px solid #333;
  margin-right: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.mask, .fill {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  clip: rect(0, 150px, 150px, 75px);
}

.mask.full {
  clip: rect(0, 75px, 150px, 0);
}

.fill {
  background-color: inherit;
  clip: rect(0, 75px, 150px, 0);
  transform: rotate(0deg);
  transition: transform 3s ease-in-out;
}

.inside-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.graphic-text {
  font-size: 20px;
  max-width: 250px;
  line-height: 1.4;
  text-align: left;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

:root {
    --main-color: #2CC157;
    --accent-pink: #EDB9FF;
    --accent-blue: #36C5F0;
    --text-color: #010216;
    --font-size-lg: 1.2rem;
    --font-size-md: 1rem;
    --font-size-sm: 0.875rem;
    --spacing-md: 16px;
    --spacing-lg: 32px;
  }
  
  .c_inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: var(--spacing-lg);
  }
  
  .content_wrapper, .list_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .left_column, .right_column {
    flex: 1;
    padding: var(--spacing-md);
  }
  
  .dot {
    width: 12px;
    height: 12px;
    background-color: var(--main-color);
    border-radius: 50%;
    display: inline-block;
  }
  
  .label {
    font-size: var(--font-size-lg);
    color: var(--text-color);
  }
  
  .text {
    font-size: var(--font-size-md);
    color: var(--text-color);
  }
  
  .accent-dot {
    color: var(--main-color);
  }
  
  .accent-dot-pink {
    color: var(--accent-pink);
  }
  
  .accent-dot-blue {
    color: var(--accent-blue);
  }
  
  /* Modificação das setas */
  .arrow, .arrow-pink, .arrow-blue {
    font-size: 18px;
    margin-right: 8px;
    display: inline-block;
  }
  
  .arrow {
    color: var(--main-color); /* Cor verde para Paid Media */
  }
  
  .arrow-pink {
    color: var(--accent-pink);
  }
  
  .arrow-blue {
    color: var(--accent-blue);
  }
  
  .point {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .point p {
    font-size: 18px;
    color: #ffffff ;/* Cor do texto das listas por padrão */
    transition: color 0.3s ease;
  }
  
  /* Responsivo */
  @media screen and (max-width: 768px) {
    .content_wrapper, .list_wrapper {
      flex-direction: column;
    }
  
    .left_column, .right_column {
      padding: var(--spacing-md) 0;
    }
  
    .c_inner {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  
  /* Estilo das colunas */
  .left_column .label, 
  .left_column .heading h2 {
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
  }
  
  .right_column .text p {
    font-size: 24px;
    color: #969696;
  }
  
  /* Efeito hover para todas as seções */
  .content_wrapper, .list_wrapper {
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
   
  
  /* Substituição do tipo de seta */
  .arrow:before, 
  .arrow-pink:before, 
  .arrow-blue:before {
    content: "→";
  }
  :root {
    --main-color: #2CC157;
    --text-color: #010216;
    --text-large: 64px;
  }
  
  /* Centralizar o contêiner principal */
.rotating-container {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
    height: 40vh; /* Altura total da tela */
    text-align: center;
    background-color:#000000
  }
  @keyframes flicker {
    0% { opacity: 1; }
    50% { opacity: 0.8; }
    100% { opacity: 1; }
  }
/* Estilo para a seção ativa */
section.active .content_wrapper .left_column .label,
section.active .content_wrapper .left_column .heading h2,
section.active .content_wrapper .right_column .text p,
section.active .list_wrapper .left_column .heading h2,
section.active .list_wrapper .right_column .text p,
section.active .list_wrapper .point p {
  color: white; /* Texto também fica branco */
}

/* Efeito hover para o texto nas listas */
section.active .list_wrapper .point:hover p {
  color: white;
}

/* Para garantir que o fundo e o texto fiquem consistentes */
section.active {
  background-color: #000; /* Fundo preto quando a seção está ativa */
  transition: background-color 0.3s ease-in-out; /* Transição suave */
}

  /* Estilização do texto principal */
  .main-text {
    font-size: 64px;
    font-weight: bold;
    color: #fff; /* Cor do texto */
    text-transform: uppercase;
    letter-spacing: 2px;
    white-space: nowrap; /* Evita quebra de linha */
    border-top: 1px solid #fff; /* Borda no topo a branco de 1 pixel */
  }
  
  
  /* Estilo do texto rotativo */
  .rotating-text {
    display: inline-block;
    width: 400px; /* Define um tamanho fixo para manter o alinhamento */
    text-align: left; /* Alinha o texto rotativo à esquerda */
    overflow: hidden;
    color:#CDFF01;
    font-size: 18px;
    animation: fadeInOut 3s linear infinite; /* Animação para aparecer/desaparecer */
  }
  
  /* Animação de fade in/out */
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    25% {
      opacity: 1;
      transform: translateY(0);
    }
    75% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  
  /* Responsividade */
  @media screen and (max-width: 768px) {
    .main-text {
      font-size: 32px; /* Tamanho menor em dispositivos móveis */
    }
    .rotating-container {
    height: 100px;
      }
    .rotating-text {
      width: 250px; /* Ajuste da largura para mobile */
    }
  }
  